import MainNavigation from "../components/UI/MainNavigation";

const ErrorPage = () => {
    return (<>
            <MainNavigation/>
            <main>
                <h1>Error weewoo</h1>
            </main>
        </>
    )
}

export default ErrorPage