import * as React from "react";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import {useIsAuthenticated} from "@azure/msal-react";
import {SignOutButton, SignInButton} from "../Auth/AuthLinks";
export const AppBar = ({pages}) => {
    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();

    const handlePathSelection = (path) => {
        if (path) {
            navigate(path);
        }
    };


    return (
        <MuiAppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{mr: 2, display: {xs: "none", md: "flex"}}}
                    >
                        CRO Client Success Portal
                    </Typography>
                    <Box sx={{flexGrow: 1, display: {xs: "none", md: "flex"}}}>
                        {pages?.map((page) => (
                            <Button
                                key={page.label}
                                onClick={() => handlePathSelection(page.path)}
                                sx={{my: 2, color: "white", display: "block"}}
                            >
                                {page.label}
                            </Button>
                        ))}
                    {isAuthenticated ? <SignOutButton sx={{my: 2, color: "white", display: "block"}} /> :
                        <SignInButton sx={{my: 2, color: "white", display: "block"}} />}
                    </Box>
                </Toolbar>
            </Container>
        </MuiAppBar>
    );
};
