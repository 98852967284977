import { useMsal } from "@azure/msal-react";
import { Button } from '@mui/material';

const loginRequest = {
    scopes: ["User.Read"]
};

export const login = (instance) => {
    instance.loginRedirect(loginRequest).catch(e => { console.log(e); });
}

export const SignInButton = ({sx}) => {
    const {instance } = useMsal()

    const login = () => {
        instance.loginRedirect(loginRequest).catch(e => { console.log(e); });
    }
    return <Button sx={sx} onClick={login}>Login</Button>
}

export const SignOutButton = ({sx}) => {
    const {instance} = useMsal()

    const logout = () => {
        instance.logoutRedirect({postLogoutRedirectUri: '/'})
            .catch(e => { console.log(e); });
    }
    return <Button sx={sx} onClick={logout}>Logout</Button>
}
