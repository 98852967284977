import {useLoaderData, useOutlet, useSubmit} from "react-router-dom";
import {AppBar} from "./UI/AppBar";
import { useEffect } from "react";
import {getTokenDuration} from "../util/auth";

export const HomeLayout = () => {
    const outlet = useOutlet();
    // const submit = useSubmit();
    // const token = useLoaderData()

    // useEffect(() => {
    //     if (!token) {
    //         return;
    //     }
    //
    //     if (token === 'EXPIRED') {
    //         submit(null, {action: '/logout', method: 'post'})
    //     }
    //
    //     const tokenDuration = getTokenDuration();
    //
    //     setTimeout(() => {
    //         submit(null, {action: '/logout', method: 'post'})
    //     }, tokenDuration);
    // }, [token, submit])
    // if (!token) {
    //     return <Navigate to="/" replace/>;
    // }

    return (<div>
            <AppBar
                pages={[
                    {label: "Home", path: "/"},
                    {label: "Clients", path: "/clients", logged_in: true},
                    {label: "PowerBI", path: "/pbi/groups", logged_in: true},
                ]}
            />
            {outlet}
        </div>);
};