import {NavLink, useRouteLoaderData} from 'react-router-dom'

const MainNavigation = () => {
    // const token = useRouteLoaderData('root')
    return <header>
        <nav>
            <ul>
                <li>
                    <NavLink to="/">Home</NavLink>
                </li>
            </ul>
        </nav>
    </header>

}

export default MainNavigation