import {IMsalContext, useMsal} from "@azure/msal-react";
import {useEffect, useState} from "react";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {Container, Table, TableBody, TableHead} from "@mui/material";

interface IAPIListTableConfig {
    classPrefix: string;
    headerDisplay: string;
    Row: any;
}

export interface IAPIReturnContent {
    id: string;
}

const ApiListTable = (
    api: (context: IMsalContext) => Promise<JSON>,
    config: IAPIListTableConfig,
    extractData: (data: any) => any = (data)=>data
) => {
    const [tableContent, setTableContent] = useState<IAPIReturnContent[]|null>(null);
    const msalcontext = useMsal();

    useEffect(() => {
        api(msalcontext).then(data => { console.log(data); setTableContent(extractData(data)); });
    }, [msalcontext])

    const defaultTheme = createTheme();

    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline/>
            <Container className={config.classPrefix + '-table'}>
                <div className="mt-5">
                    {tableContent &&
                        <Table>
                            <TableHead>
                                <tr>
                                    <th className={config.classPrefix + '-table-header__name'}>
                                        {config.headerDisplay}
                                    </th>
                                </tr>
                            </TableHead>
                            <TableBody className={config.classPrefix + "-table_body"}>
                                {tableContent.map((info)=> (
                                    <config.Row key={info.id} className={config.classPrefix + '-table_row'} data={info} />
                                ))}
                            </TableBody>
                        </Table>
                    }
                </div>
            </Container>
        </ThemeProvider>
    )
}

export default ApiListTable;