import {Button, Container} from "@mui/material"
import {AuthenticatedTemplate} from "@azure/msal-react";
import {useMsal} from "@azure/msal-react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import * as React from "react";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {useNavigate} from "react-router-dom";


const Copyright = () => {
    return (
        <Typography variant="body2" color="text.secondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://newmine.com/">
                Newmine.com
            </Link>{' '}
            {new Date().getFullYear()}
        </Typography>
    );
}

const ProfileContent = () => {
    const { accounts } = useMsal();

    return (
        <>
            <div className="card-title">Welcome {accounts[0].name}</div>
        </>
    );
};


const defaultTheme = createTheme();

const HomePage = () => {
    const navigate = useNavigate();
    const onClickHandler = () => {
        navigate("/clients");
    }

    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline/>
            <main>
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        pt: 8,
                        pb: 6,
                    }}
                >
                    <Container maxWidth="sm">
                        <Typography
                            component="h2"
                            variant="h3"
                            align="center"
                            color="text.primary"
                            gutterBottom
                        >
                            <AuthenticatedTemplate>
                                <ProfileContent/>
                            </AuthenticatedTemplate>
                        </Typography>
                        <Typography variant="h5" align="center" color="text.secondary" paragraph>
                            Client Success Portal to view logs and run frequently asked jobs
                        </Typography>
                        <Stack
                            sx={{pt: 4}}
                            direction="row"
                            spacing={2}
                            justifyContent="center"
                        >
                            <Button variant="contained" onClick={onClickHandler}>Head to Clients List</Button>
                        </Stack>
                    </Container>
                </Box>
            </main>
            {/* Footer */}
            <Box sx={{bgcolor: 'background.paper', pt: 40}} component="footer">
                <Copyright/>
            </Box>
            {/* End footer */}
        </ThemeProvider>
    );
};

export default HomePage