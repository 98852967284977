import {TableCell, TableRow} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import {IAPIReturnContent} from "./ApiListTable";
import {MouseEvent} from "react";


interface IAPITableRowConfig {
    name: string;
    display?: (data: IAPIReturnContent) => any;
}

const ApiTableRow = (navigateUrl: (data: any) => string, config: IAPITableRowConfig[], classPrefix: string) => {
    const navigate = useNavigate();

    return ({data}: {data: IAPIReturnContent}) => {
        const displayFn = (row: IAPITableRowConfig, col: any) => {
            if (row.display) {
                return row.display(col);
            }
            return col[row.name];
        }

        const navigateToDetails = (e: MouseEvent) => {
           e.stopPropagation();
           navigate(navigateUrl(data));
        }
        return (
            <TableRow className={classPrefix + "-table_row"} hover onClick={navigateToDetails}>
                {config.map((row) => (
                    <TableCell key={row.name} className={classPrefix + '-client-table__' + row.name}>
                        {displayFn(row, data)}
                    </TableCell>
                ))}
            </TableRow>
        )
    }
}

export default ApiTableRow;