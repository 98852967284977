import './WorksapceTable.css'
import './GroupTableRow.css'
import {apiCall} from "../../apiRequests";
import ApiListTable from "../tables/ApiListTable";
import ApiTableRow from "../tables/TableRow";
import {useParams} from "react-router-dom";
import {useLocation} from "react-use";


export const GroupTable = () => {
    const groupApiCall = apiCall('pbi/groups');

    const rowConfig = [
        {name: 'name'},
        {name: 'type'}
    ]
    const GroupRow = ApiTableRow((data)=> `/pbi/groups/${data.id}`, rowConfig, 'Workspaces');

    return ApiListTable(groupApiCall, {classPrefix: 'workspace', headerDisplay: 'Workspaces', Row: GroupRow},
        (data)=>data.value, );
}


export const DashboardsTable = () => {
    const {group_id} = useParams();
    const api = apiCall(`pbi/${group_id}/datasets`);

    const {state} = useLocation();

    const rowConfig = [
        {name: 'name'},
        {name: 'createdDate', display: (data) => { return new Date(data.createdDate).toLocaleDateString(); }},
        {name: 'isRefreshable'}
    ]
    const DashboardRow = ApiTableRow(
        (data)=>{ let r = `/pbi/groups/${group_id}/dashboards/${data.id}`; console.log(r); return r; },
        rowConfig,
        'dashboards'
    );

    return ApiListTable(api, {classPrefix: 'dashboards', headerDisplay: `Dashboards - ${state.groupName}`, Row: DashboardRow});
}

export const RefreshesTable = () => {
    const {group_id, dashboard_id} = useParams();
    const api = apiCall(`pbi/${group_id}/datasets/${dashboard_id}/refreshes`);

    const asDate = (col) => (data) => { return new Date(data[col]).toLocaleDateString(); }
    const rowConfig = [
        {name: 'refreshType'},
        {name: 'startTime', display: asDate('startTime')},
        {name: 'endTime', display: asDate('endTime')},
        {name: 'status'}
    ]
    const RefreshRow = ApiTableRow(
        (data)=>'', rowConfig, 'Refreshes'
    );
    console.log('Dashboards Table')

    return ApiListTable(api, {classPrefix: 'refresh', headerDisplay: 'Refreshes', Row: RefreshRow},
        (data) => data.value);
}
