import {apiRequest} from "./authConfig";

export const base_url = process.env.REACT_APP_API_BACKEND_URI

export const getToken = (instance, accounts) => {
    return instance.acquireTokenSilent({
        ...apiRequest,
        account: accounts[0]
    })
        .then((response) => response.accessToken);
}

export const getTokenThenCall = ({instance, accounts}, api, asjson, method="GET") => {
    return getToken(instance, accounts).then((token) => {
        const header = new Headers()
        header.append('Authorization', `Bearer ${token}`)

        const retv = fetch(`${base_url}/${api}`, {headers: header, method: method})
            .catch((e) => console.error(`API Call Error (${api}): ${e})`))
        return asjson ? retv.then((resp) => resp.json()) : retv;
    });
}

export const apiCall = (api, asjson = true) => (msalcontext) => getTokenThenCall(msalcontext, api, asjson);

export const apiPost = (api, asjson = true) => (msalcontext) => getTokenThenCall(msalcontext, api, asjson, "POST");


export const listSites = apiCall('list-sites');
export const siteInfo = (client) => apiCall(`site-info/${client}`);
export const avoidanceReport = (client) => apiCall(`avoidance-reports/${client}`, false);
export const systemReport = (client) => apiCall(`system-reports/${client}`, false);
export const clientReport = (client) => apiCall(`client-reports/${client}`, false)
export const clientCredential = (client) => apiCall(`client-credential/${client}`);
export const ncm = (client) => apiPost(`run-ncm/${client}`, false);
export const ade = (client) => apiPost(`run-ade/${client}`, false);

export const curve = (client) => apiPost(`run-curve/${client}`, false);
