import {
    createBrowserRouter, redirect,
    RouterProvider
} from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ErrorPage from "./pages/Error";
import HomePage from "./pages/Home"
import {HomeLayout} from "./components/HomeLayout";
import { lazy, Suspense } from "react";
import {login} from "./components/Auth/AuthLinks";

import {
    MsalProvider,
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
    useIsAuthenticated
} from "@azure/msal-react";

import {InteractionStatus} from "@azure/msal-browser";
import {GroupTable, DashboardsTable, RefreshesTable} from "./components/dashboards/WorkspaceTable";

const Clients = lazy(() => import('./pages/Clients'))
const ClientPage = lazy(() => import('./pages/ClientPage'))


const App = ({msal}) => {
    // const navigate = useNavigate();
    // const navclient = new CustomNavigationClient(navigate)
    // msal.setNavigationClient(navclient);

    return (
        <MsalProvider instance={msal}>
            <Urls />
        </MsalProvider>
    );
};

function Urls () {
    const auth = useIsAuthenticated();
    const {instance, inProgress } = useMsal()
    const isAuth = () => {
        return auth ? null : inProgress === InteractionStatus.None && login(instance);
    }

    const Loading = (content) => (<Suspense fallback={<p>Loading...</p>}>{content}</Suspense>)
    const router = createBrowserRouter([{
        element: <HomeLayout/>,
        children: [
            {path: '/', element: <HomePage/>},
            {path: 'home', element: <HomePage/>},
            {
                path: 'clients',
                children: [
                    {
                        index: true,
                        element: Loading(<Clients/>),
                        loader: isAuth,
                    },
                    {
                        path: ':clientId',
                        element: Loading(<ClientPage/>),
                        loader: isAuth,
                    }
                ]
            },
            {
                path: 'pbi',
                children: [
                    { path: 'groups', loader: isAuth, element: Loading(<GroupTable />) },
                    { path: 'groups/:group_id', loader: isAuth, element: Loading(<DashboardsTable />) },
                    { path: 'groups/:group_id/dashboards/:dashboard_id', loader: isAuth, element: Loading(<RefreshesTable />) }
                ]
            },
            {path: '*', element: <ErrorPage/>}
        ]
    }]);

    return (<RouterProvider router={router}/>)
}

export default App;

